form {
    line-height: 1rem;
    margin-bottom: 1rem !important;

    .row {
        margin-top: 1rem;

        label {
            max-width: 100%;

            .form-control {
                margin-top: .25rem;
                max-width: 100%;
            }
        }
    }
}
