// Setting up gent_styleguide (cfr. https://stijlgids.stad.gent/v4/index.html)
@use '@angular/material' as mat;

// Custom Theming for Angular Material
// For more faq: https://material.angular.io/guide/theming

// Plus imports for other components in your app.
$styleguide-dir: "" !default;
@import '../../../../node_modules/gent_styleguide/build/styleguide/sass/main_cli';
@import '../../../../node_modules/angular-calendar/css/angular-calendar.css';
@import '../../../../node_modules/leaflet/dist/leaflet.css';
@import '../theme';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theming-material-components-theme);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include mat.all-component-typographies($typography);

// Bootstrap utility components
@import 'bootstrap/dist/css/bootstrap-grid.min.css';
@import 'bootstrap/dist/css/bootstrap-utilities.min.css';

// App components
@import 'components/forms';
@import 'components/modals';
@import 'components/buttons';
@import 'components/alerts';
@import 'components/tables';
@import 'components/calendar';
@import 'components/tabs';
@import 'components/material';
@import 'components/cards';
@import 'components/scanner';
@import 'components/container';

/* You can add global styles to this file, and also import other style files */
body {
    overflow-y: scroll;

    hr {
        border: 1px solid whitesmoke;
        border-radius: 2px;
        margin-bottom: 1rem;
    }

    .messages {
        i {
            align-self: center;
        }
    }

    .center {
        margin-left: auto;
        margin-right: auto;
        max-width: 800px;
    }

    /*
     * Class definition for a rotating loader
     */
    .loader {
        border: 10px solid #f3f3f3;
        border-radius: 50%;
        border-top: 10px double #3498db;
        width: 60px;
        height: 60px;
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 2s linear infinite;
    }

    .text-red {
        color: indianred;
    }
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/**
 * Class to make sure that the options of the Material Dropdown inside a
 * Bootstrap Modal is at the foreground.
 * Solution found at: https://stackoverflow.com/a/63051872/9356123
 */
.cdk-global-overlay-wrapper {
    padding: 2rem 0;
    .cdk-overlay-pane {
        overflow-y: auto;
    }
}

video {
    width: 100%;
}

// Safari specific coloring of input fields
@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        .input-true {
            border: 1px solid rgba(245, 64, 64, 0.767) !important;
        }

        .input-false {
            border: 1px solid rgba(128, 128, 128, 0.678) !important;
        }
    }
}
