button, .button {
    display: flex;
    align-items: center;
    justify-content: center;

    &.button-alert {
        background: indianred;
        border-color: indianred;

        &:hover {
            background: #b85252;
            border-color: #b85252;
        }
    }

    &[disabled], &.disabled {
        background-color: white !important;
        border: 2px solid lightgrey !important;
        color: darkgrey !important;
    }
}

article {
    button, .button {
        min-width: 130px;
    }
}
