.calendar-event-NR * * mwl-calendar-event-title {
    color: black;
}

.calendar-event-reserved * * mwl-calendar-event-title {
    color: #ffffff;
}

.calendar-event-NR span {
    white-space: normal !important;
}

.calendar-event-reserved span {
    white-space: normal !important;
}

.calendar-event-full-not-reserved * * mwl-calendar-event-title {
    color: #a32707;
}

.calendar-event-full-not-reserved span {
    white-space: normal !important;
}

.calendar-event-suggestion {
    opacity: 0.6;
}

.cal-week-view, .cal-day-view {
    .event {
        .cal-event {
            background: #c9e3ee;
            border-color: #007db3;

            .cal-event-title {
                color: #007db3;
            }
        }

        &.pending {
            .cal-event {
                background: #F7934C;
                border-color: #F7934C;

                .cal-event-title {
                    color: white;
                }
            }
        }

        &.approved, &.selected, &.present {
            .cal-event {
                background: #007db3;
                border-color: white;

                .cal-event-title {
                    color: white;
                }
            }
        }

        &.rejected {
            .cal-event {
                background: #feeee6;
                border-color: indianred;

                .cal-event-title {
                    color: indianred;
                }
            }
        }

        &.absent {
            .cal-event {
                background: indianred;
                border-color: indianred;

                .cal-event-title {
                    color: white;
                }
            }
        }
    }
}

.cal-month-view {
    .cal-event {
        background: #0071a1;
    }

    .cal-day-badge {
        background: whitesmoke;
        color: #555;
    }
}
