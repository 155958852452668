.cdk-overlay-container {
    z-index: 1002;

    .cdk-overlay-pane {
        max-width: 800px !important;
        width: calc(100% - 2rem) !important;
        border-radius: .5rem;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 8px;
            background-color: whitesmoke;
            border: 1px solid lightgrey;
            box-shadow: inset 0 0 6px lightgrey;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: #3297c2;
        }

        .modal-full {
            padding: 24px;

            .modal-header-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 1rem;
                border-bottom: 2px solid whitesmoke;
                margin-bottom: 1rem;

                .modal-header {
                    * {
                        margin: 0;
                        color: #007db3;
                    }
                }

                .close {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 40px;
                    min-height: 40px;
                    width: 40px;
                    padding: 0;
                    height: 40px;
                    border-radius: 100%;
                    font-size: 21px;
                }
            }
        }

        .modal-footer {
            padding-top: 1rem;
            border-top: 2px solid whitesmoke;

            > div {
                display: flex;
                gap: 1rem;
            }
        }

        .modal-message-footer {
            margin-top: -1rem;
        }
    }
}
